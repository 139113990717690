<template>
  <div class="flex justify-center">
    <div class="flex justify-between">
      <v-menu>
        <template #activator="{ props }">
          <div class="rounded bg-bg_color cursor-pointer px-1 border w-24">
            <div class="flex justify-between" v-bind="props">
              <div>
                {{ selectedMonth ? listMonth[selectedMonth] : t('month') }}
              </div>
              <div class="w-2"></div>
              <v-icon icon="mdi mdi-menu-down" :size="24" />
            </div>
          </div>
        </template>
        <div class="w-3"></div>
        <v-list class="mx-auto dropdown-menu-local">
          <div
            v-for="(month, number) in listMonth"
            :key="`Month-item-${month}`"
            class="w-24 cursor-pointer p-2"
            @click="selectMonth(number)"
          >
            {{ month }}
          </div>
        </v-list>
      </v-menu>
      <div class="w-3"></div>
      <v-menu>
        <template #activator="{ props }">
          <div
            class="rounded bg-bg_color cursor-pointer px-1 border w-24"
          >
            <div class="flex justify-between items-center" v-bind="props">
              <div>
                {{ selectedYear || t('year') }}
              </div>
              <div class="w-2"></div>
              <v-icon icon="mdi mdi-menu-down" :size="24" />
            </div>
          </div>
        </template>
        <v-list class="mx-auto dropdown-menu-local">
          <div
            v-for="year in listYears"
            :key="`year-item-${year}`"
            class="w-24 cursor-pointer p-2"
            @click="selectYear(year)"
          >
            {{ year }}
          </div>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
function selectMonth(monthNumber: number) {
  if (route.query.month?.toString() !== monthNumber?.toString()) {
    selectedMonth.value = monthNumber
    useQueryNavigation({ month: monthNumber })
  }
}
function selectYear(year: number) {
  if (route.query.year?.toString() !== year?.toString()) {
    selectedYear.value = year
    useQueryNavigation({ year: selectedYear.value })
  }
}
const dateNow = new Date()
function getYears() {
  const listYears: number[] = []

  const dateRange = { start: new Date(2022, 0), end: dateNow }

  const differenceYears =
    dateRange.end.getFullYear() - dateRange.start.getFullYear()

  for (let i = 0; i <= differenceYears; i++) {
    listYears.push(dateRange.start.getFullYear() + i)
  }
  return listYears
}
const listMonth: Record<number, string> = {
  1: t('january'),
  2: t('february'),
  3: t('march'),
  4: t('april'),
  5: t('may'),
  6: t('june'),
  7: t('july'),
  8: t('august'),
  9: t('september'),
  10: t('october'),
  11: t('november'),
  12: t('december')
}
const listYears = getYears()
const selectedYear = ref<number | undefined>(parseFloat(route.query.year?.toString() || ''))
const selectedMonth = ref<number | undefined>(parseFloat(route.query.month?.toString() || ''))
</script>
<style scoped>
.dropdown-menu-local {
  @apply bg-green-100 mt-2   max-h-48;
}
</style>
