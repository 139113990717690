<template>
  <MyAccountCheckAuthLoggedIn>
    <div class="flex flex-col items-stretch items-start justify-center">
      <MyAccountMoneyMovementsBalances />
      <MyAccountMoneyMovementsFilter />

      <MyAccountMoneyMovementsGrid :key="`MyAccountMoneyMovementsGrid ${query.month} ${query.year}`" />
    </div>
  </MyAccountCheckAuthLoggedIn>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const route = useRoute()
const query = ref<{ year: any; month: any }>({
  year: route.query.year,
  month: route.query.month
})
const { t } = useI18n()
const { store } = useDomainState()

watch(
  () => route.query || route.query,
  (newValue) => {
    if (newValue.month && newValue.year) {
      query.value.month = newValue.month
      query.value.year = newValue.year
    }
  }
)

useServerSeoMeta(
  useOgMeta(
    url + '/my-account/money-movements',
    t('money_movements'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('money_movements'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/my-account/money-movements')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('money_movements'), url + '/my-account/money-movements')
    ])
  ]
})
</script>
